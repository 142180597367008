
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Tray extends Vue {
  @Prop(Boolean) readonly isOpen!: boolean

  @Prop({
    type: Boolean,
    default: true,
  })
  readonly full!: boolean

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly fit!: boolean

  @Prop({
    type: String,
    default: 'right',
    validator: (side: string) => ['left', 'right'].includes(side),
  })
  readonly side!: string

  @Prop({
    type: Boolean,
    default: true,
  })
  readonly closeOnClickOutside!: boolean

  @Prop(String) readonly additionalClasses!: string

  @Prop(Boolean) readonly keepActive!: boolean

  get trayClasses(): string {
    return [
      this.side === 'right' ? `right-0 ${this.isOpen ? '-translate-x-0' : 'translate-x-full'}` : `left-0 ${this.isOpen ? 'translate-x-0' : '-translate-x-full'}`,
      this.fit ? '' :'h-full',
      this.full ? 'top-0' : ''
    ].join(' ')
  }
}
